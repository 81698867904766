import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { useMatch } from '@reach/router';
import { InitOptimize } from 'util/google_optimize/optimize_helper';
import classNames from 'classnames';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import withSecurity from 'hoc/withSecurity';
import withSessionExpiration from 'hoc/withSessionExpiration';

import Navigation from 'components/navigation/navigation.component';

import withBackgroundImage from 'hoc/withBackgroundImage';
import SEO from 'components/seo/seo.component';
import BirdiModal from 'components/birdi-modal/birdi-modal';

import './workflow.layout.scss';

const WorkflowLayout = ({
    children,
    className,
    metaData
}: {
    children: any;
    className?: string;
    metaData?: SEOProps;
}) => {
    const data = AlertBannerAndMenusQuery();
    const location = useMatch('/secure/*');
    const isSecurePage = location !== null;

    const paths = data.allMenuLinkContentMain.nodes;
    const filteredPaths = paths.filter((navItem: { requiresAccountAuth: boolean }) =>
        isSecurePage ? navItem.requiresAccountAuth : !navItem.requiresAccountAuth
    );

    useEffect(() => {
        InitOptimize();
    }, []);

    const classes = classNames('page', className);

    return (
        <div className={`workflowLayout ${classes}`}>
            <SEO
                nodeTitle={metaData?.nodeTitle}
                title={metaData?.title}
                abstract={metaData?.abstract}
                description={metaData?.description}
                keywords={metaData?.keywords}
                children={children}
            />
            <Navigation paths={filteredPaths} isSecurePage={isSecurePage} />
            <Container fluid={'xl'} className="px-0 px-xl-3">
                <Row className="no-gutters" style={{ marginTop: '8rem', paddingBottom: '8rem' }}>
                    <Col xs={12} md={{ span: 8, offset: 2 }}>
                        {children}
                    </Col>
                </Row>
            </Container>
            <BirdiModal />
        </div>
    );
};

export default withSessionExpiration(withSecurity(withBackgroundImage(WorkflowLayout, 'workflowPageBackground')));

export const AlertBannerAndMenusQuery = () => {
    const AlertBannerAndMenus = useStaticQuery<any>(graphql`
        {
            allBlockContentAlertBanner(
                sort: { fields: changed, order: DESC }
                filter: { field_alert_active: { eq: true } }
            ) {
                nodes {
                    field_alert_text
                    field_alert_priority_level
                    field_alert_permanent
                    field_alert_link {
                        title
                        uri
                    }
                }
            }
            allMenuLinkContentMain(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
                nodes {
                    isButton: field_menu_cta
                    link {
                        url
                    }
                    label: title
                    requiresAccountAuth: field_requires_account_auth
                    langcode
                }
            }
        }
    `);
    return AlertBannerAndMenus;
};
