import React, { ReactElement, useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useAddTransferPrescription } from 'hooks/useAddTransferPrescription';
import AddPhotoLayoutWrapper from 'components/add-photo-layout-wrapper/add-photo-layout-wrapper.component';
import { Row, Col } from 'react-bootstrap';
import Button from 'ui-kit/button/button';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import './upload-photo.style.scss';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { photoUploadRoutine } from 'state/add-transfer-prescription/add-transfer-prescription.routines';
import { clearAddTransferObject } from 'state/add-transfer-prescription/add-transfer-prescription.reducers';
import ImageUpload from './upload';

const UploadPhoto = (): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { setPrescriptionFlowType } = useAddTransferPrescription();
    const [imageIsUploaded, setImageIsUploaded] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const [imageSrc, setImageSrc] = useState('');
    const [cropper, setCropper] = useState<any>();
    const [isProcessingImage, setIsProcesingImage] = useState(false);
    const handleImageSelect = (e: any) => {
        setImageSrc(URL.createObjectURL(e.target.files[0]));
    };
    const cropperRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        setImageSrc('');
        setIsProcesingImage(false);
        setUploadError(false);
        dispatch(clearAddTransferObject());
    }, []);
    const handleSubmitClick = () => {
        if (typeof cropper !== 'undefined') {
            setUploadError(false);
            setIsProcesingImage(true);
            dispatch(
                photoUploadRoutine.trigger({
                    imageUrl: cropper.getCroppedCanvas().toDataURL().split('base64,')[1],
                    onSuccess: () => {
                        setIsProcesingImage(false);
                        setPrescriptionFlowType({ flowType: 'Transfer' });
                        navigate('/secure/prescription/');
                    },
                    onFailure: () => {
                        setUploadError(true);
                        setIsProcesingImage(false);
                    }
                })
            );
        }
    };
    const changePhotoClick = () => {
        setImageSrc('');
    };
    const imageData = useStaticQuery(graphql`
        query {
            pills: file(relativePath: { eq: "assets/images/transfer-rx-prescription.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
            pillBottle: file(relativePath: { eq: "assets/images/pill-bottle.png" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
        }
    `);

    const handleBackClick = () => {
        setPrescriptionFlowType({ flowType: 'Transfer' });
        navigate('/secure/prescription/');
    };
    const handleCloseClick = () => {
        setPrescriptionFlowType({ flowType: 'Transfer' });
        navigate('/secure/prescription/');
    };
    useEffect(() => {
        setImageIsUploaded(imageSrc.length > 0);
    }, [imageSrc]);

    return (
        <AddPhotoLayoutWrapper
            eyebrowText={
                !imageIsUploaded
                    ? t('pages.uploadPrescriptionPhoto.eyebrowText')
                    : t('pages.uploadPrescriptionPhoto.eyebrowTextCrop')
            }
            title={
                !imageIsUploaded
                    ? t('pages.uploadPrescriptionPhoto.title')
                    : t('pages.uploadPrescriptionPhoto.titleCrop')
            }
            backgroundImage={imageData.pills}
            handleBackClick={handleBackClick}
            handleCloseClick={imageIsUploaded && handleCloseClick}
        >
            <Row className="upload-photo--wrapper d-flex justify-content-center mb-4">
                {!imageIsUploaded && (
                    <>
                        <Col xs={12} md={6} lg={5} className="text-center">
                            <GatsbyImage image={getImage(imageData.pillBottle)} alt={''} style={{ maxWidth: 300 }} />
                        </Col>
                        <Col xs={12} md={6} lg={7}>
                            <ul>
                                <li>
                                    <h4>{t('pages.uploadPrescriptionPhoto.inASnapInstructions.firstBullet.text')}</h4>
                                    <ul>
                                        <li>
                                            <h4>
                                                {t(
                                                    'pages.uploadPrescriptionPhoto.inASnapInstructions.firstBullet.firstSubBullet'
                                                )}
                                            </h4>
                                        </li>
                                        <li>
                                            <h4>
                                                {t(
                                                    'pages.uploadPrescriptionPhoto.inASnapInstructions.firstBullet.secondSubBullet'
                                                )}
                                            </h4>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h4>{t('pages.uploadPrescriptionPhoto.inASnapInstructions.secondBullet')}</h4>
                                </li>
                            </ul>
                        </Col>
                    </>
                )}
                {imageIsUploaded && (
                    <Col className="crop-container">
                        <Cropper
                            src={imageSrc}
                            guides={false}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            autoCropArea={1}
                            autoCrop={true}
                            background={false}
                            movable={true}
                            rotatable={true}
                            ref={cropperRef}
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                        />
                    </Col>
                )}
            </Row>
            <Row className={imageIsUploaded ? 'mb-5' : ''}>
                {imageIsUploaded && (
                    <>
                        <Col sm={12}>
                            <div className="crop-help">{t('pages.uploadPrescriptionPhoto.cropFooterInfo')}</div>
                        </Col>
                        {uploadError && (
                            <Col sm={8} className="offset-md-2">
                                <div className="crop-help text-warning">
                                    <Button
                                        variant="text"
                                        label={t('pages.uploadPrescriptionPhoto.uploadError')}
                                        type="submit"
                                        className="my-2 mx-md-2 d-block sm-full d-sm-inline text-warning"
                                        onClick={handleBackClick}
                                    />
                                </div>
                            </Col>
                        )}
                    </>
                )}
            </Row>
            <Row className="justify-content-center mt-5 mb-4">
                <Col className="col-12 text-center">
                    {imageIsUploaded && (
                        <>
                            <Button
                                variant="outline-primary"
                                label={t('pages.uploadPrescriptionPhoto.useDifferentPhotoButton')}
                                type="submit"
                                className="my-2 mx-md-2 d-block sm-full d-sm-inline"
                                onClick={changePhotoClick}
                            />
                            <Button
                                variant="primary"
                                label={t('pages.uploadPrescriptionPhoto.submitButton')}
                                type="submit"
                                className="my-2 mx-md-2 d-block sm-full d-sm-inline"
                                isBusy={isProcessingImage}
                                async
                                onClick={handleSubmitClick}
                            />
                        </>
                    )}
                    <ImageUpload
                        handleImageSelect={handleImageSelect}
                        imageSrc={imageSrc}
                        buttonLabel={t('pages.uploadPrescriptionPhoto.addPhotoButton')}
                    />
                </Col>
            </Row>
        </AddPhotoLayoutWrapper>
    );
};

export default UploadPhoto;
