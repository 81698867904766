import React from 'react';
import classNames from 'classnames';
import { Container, Row, Col } from 'react-bootstrap';

import { AddPhotoLayoutWrapperProps } from 'components/add-photo-layout-wrapper/add-photo-layout-wrapper.props';

import './add-photo-layout-wrapper.style.scss';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import Button from 'ui-kit/button/button';
import RemoveIcon from 'ui-kit/icons/remove-icon/remove-icon';

const AddPhotoLayoutWrapper = ({
    children,
    className,
    eyebrowText,
    title,
    backgroundImage,
    handleBackClick,
    handleCloseClick
}: AddPhotoLayoutWrapperProps) => {
    const { t } = useTranslation();
    const classes = classNames('add-photo-layout-wrapper', className);

    return (
        <WorkflowLayout backgroundImage={backgroundImage}>
            <div className={classes}>
                <Container fluid>
                    <Row className={`add-photo-layout-wrapper-header d-flex flex-column`}>
                        <Col className="d-flex justify-content-between p-0 col-12 mb-4 pr-4 text-primary">
                            <Button
                                variant="back"
                                label={t('prescriptionLayout.back')}
                                type="submit"
                                onClick={handleBackClick}
                            />
                            {handleCloseClick && <RemoveIcon size={'lg'} onClick={handleCloseClick} />}
                        </Col>
                        <Col className="d-flex flex-column justify-content-center align-items-center col-12">
                            {eyebrowText && <h5>{eyebrowText}</h5>}
                            <h1 className="h2">{title}</h1>
                            <div className="spacer" />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="add-photo-layout-wrapper-content">{children}</Col>
                    </Row>
                </Container>
            </div>
        </WorkflowLayout>
    );
};

export default AddPhotoLayoutWrapper;
