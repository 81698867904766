import { useDispatch, useSelector } from 'react-redux';

import {
    FlowTypePayload,
    clearAddTransferObject,
    setStep,
    setFlowType,
    setTotalStep,
    setShowPaymentStep,
    setShowAddressStep
} from 'state/add-transfer-prescription/add-transfer-prescription.reducers';
import { addTransferPrescriptionStepSelector } from 'state/add-transfer-prescription/add-transfer-prescription.selectors';
import { accountCreditCardsSelector, accountProfileAddressesSelector } from 'state/account/account.selectors';

export const useAddTransferPrescription = () => {
    const currentStepState = useSelector(addTransferPrescriptionStepSelector);
    const profileAddresses = useSelector(accountProfileAddressesSelector);
    const allPaymentData = useSelector(accountCreditCardsSelector);
    const dispatch = useDispatch();

    const setPrescriptionFlowType = (flowType: FlowTypePayload) => {
        dispatch(setFlowType(flowType));
    };
    const resetForm = () => {
        dispatch(clearAddTransferObject());
    };
    const incrementStep = () => {
        dispatch(setStep({ currentStep: currentStepState + 1 }));
    };
    const decrementStep = () => {
        dispatch(setStep({ currentStep: currentStepState - 1 }));
    };
    const checkPaymentExists = () => {
        if (allPaymentData === undefined || allPaymentData.length === 0) {
            dispatch(setShowPaymentStep({ showPaymentStep: true }));
            return 1;
        } else {
            dispatch(setShowPaymentStep({ showPaymentStep: false }));
            return 0;
        }
    };
    const checkShippingExists = () => {
        if (profileAddresses === undefined || profileAddresses.length === 0) {
            dispatch(setShowAddressStep({ showAddressStep: true }));
            return 1;
        } else {
            dispatch(setShowAddressStep({ showAddressStep: false }));
            return 0;
        }
    };
    const setTotalNumberOfSteps = (totalSteps: number) => {
        return (paymentStep: any) => {
            return (addressStep: any) => {
                dispatch(setTotalStep({ totalSteps: totalSteps + paymentStep + addressStep }));
            };
        };
    };

    return {
        resetForm,
        incrementStep,
        decrementStep,
        setPrescriptionFlowType,
        checkPaymentExists,
        checkShippingExists,
        setTotalNumberOfSteps
    };
};
