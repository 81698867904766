import React, { useRef } from 'react';

function ImageUpload({
    imageSrc,
    handleImageSelect,
    buttonLabel = 'Upload Photo'
}: {
    imageSrc: any;
    handleImageSelect: any;
    buttonLabel?: string;
}) {
    let inputFile = useRef<HTMLInputElement | undefined>(null);

    const uploadClick = (e: any) => {
        e.preventDefault();
        inputFile.click();
        return false;
    };
    return (
        <div>
            <div className="create-book-photo-picker">
                <div
                    className="create-book-image-input-container"
                    style={{
                        overflow: 'hidden',
                        width: '100%',
                        height: '100%'
                    }}
                >
                    <input
                        type="file"
                        name="photo"
                        className="create-book-photo-input d-none"
                        placeholder="select the book photo"
                        style={{
                            opacity: 0,
                            width: '100%',
                            height: '100%'
                        }}
                        accept="image/*"
                        onChange={handleImageSelect}
                        onClick={(event) => {
                            event.target.value = null;
                        }}
                        ref={(input) => {
                            // assigns a reference so we can trigger it later
                            inputFile = input;
                        }}
                    />
                </div>
                {!imageSrc && (
                    <input value={buttonLabel} type="button" className="btn btn-primary" onClick={uploadClick} />
                )}
            </div>
        </div>
    );
}

export default ImageUpload;
